<template>
  <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
    <cz-dialog
      v-bind="$attrs"
      v-on="$listeners"
      :width="750"
      max-width="90%"
      :title="dialogTitle"
      inset
      :loading="loading"
    >
      <v-form @submit.prevent="$emit('on-submit', form)" :disabled="loading">
        <v-row align="center" dense>
          <v-col :xl="4" :lg="6" :md="12">
            <cz-form-field
              :label="$t('customer.catalogManagementPage.itemCode')"
              required
            >
              <validation-provider
                rules="required|max:17"
                :name="$t('customer.catalogManagementPage.itemCode')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.itemCode"
                  :error-messages="errors"
                  :max-length="17"
                  :placeholder="
                    $t('customer.catalogManagementPage.itemCodeHint')
                  "
                  :readonly="isEditMode"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :xl="4" :lg="6" :md="12">
            <cz-form-field
              :label="$t('customer.catalogManagementPage.itemName')"
              required
            >
              <validation-provider
                rules="required|max:30"
                :name="$t('customer.catalogManagementPage.itemName')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.itemName"
                  :error-messages="errors"
                  :max-length="30"
                  :placeholder="
                    $t('customer.catalogManagementPage.itemNameHint')
                  "
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :xl="4" :lg="6" :md="12">
            <cz-form-field
              :label="$t('customer.catalogManagementPage.unitOfMeasure')"
              required
            >
              <validation-provider
                rules="required"
                :name="$t('customer.catalogManagementPage.unitOfMeasure')"
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  v-model="form.itemUnitOfMeasure"
                  :error-messages="errors"
                  :placeholder="$t('common.selectValue')"
                  :items="unitOfMeasures"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :xl="4" :lg="6" :md="12">
            <cz-form-field
              :label="$t('customer.catalogManagementPage.priceType')"
              required
            >
              <validation-provider
                rules="required"
                :name="$t('customer.catalogManagementPage.priceType')"
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  v-model="form.priceType"
                  :error-messages="errors"
                  :items="priceTypes"
                  :placeholder="$t('common.selectValue')"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :xl="4" :lg="6" :md="12">
            <cz-form-field
              :label="$t('customer.catalogManagementPage.itemPrice')"
              required
            >
              <validation-provider
                :rules="form.priceType === PriceType.NO_PRICE ? '' : 'required'"
                :name="$t('customer.catalogManagementPage.itemPrice')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.itemPrice"
                  :error-messages="errors"
                  :disabled="form.priceType === PriceType.NO_PRICE"
                  type="number"
                  :placeholder="
                    $t('customer.catalogManagementPage.itemPriceHint')
                  "
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :xl="4" :lg="6" :md="12">
            <cz-form-field
              :label="$t('customer.catalogManagementPage.currency')"
              required
            >
              <validation-provider
                rules="required"
                :name="$t('customer.catalogManagementPage.currency')"
                v-slot="{ errors }"
              >
                <cz-currency-picker
                  v-model="form.currency"
                  :error-messages="errors"
                  :items="currencyTypes"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :cols="12">
            <cz-form-field
              :label="$t('customer.catalogManagementPage.description')"
              required
            >
              <validation-provider
                rules="required|max:200"
                :name="$t('customer.catalogManagementPage.description')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.itemDescription"
                  multi-line
                  :error-messages="errors"
                  no-resize
                  :max-length="200"
                  :counter="200"
                  :rows="3"
                  :placeholder="
                    $t('customer.catalogManagementPage.itemShortDescHint')
                  "
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <cz-button
            :title="
              isEditMode
                ? $t('customer.catalogManagementPage.updateItemButtonTitle')
                : $t('customer.catalogManagementPage.createItemButtonTitle')
            "
            color="primary"
            type="submit"
            large
            class="px-6"
            :disabled="invalid || loading || !formChanged"
          />
        </div>
      </v-form>
    </cz-dialog>
  </validation-observer>
</template>

<script>
import {
  CzDialog,
  CzFormField,
  CzInput,
  CzButton,
  CzAutocomplete,
  CzCurrencyPicker
} from '@/components';
import currencyTypes from '@/shared/types/CurrencyTypes';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { unitOfMeasureItems, priceTypesItems } from '../constants';
import PriceType from '../types/PriceType';
export default {
  name: 'ManageCatalogItemDialog',
  components: {
    CzDialog,
    CzFormField,
    CzInput,
    CzButton,
    CzAutocomplete,
    CzCurrencyPicker
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          itemCode: '',
          itemName: '',
          currency: 'ILS',
          itemUnitOfMeasure: '',
          priceType: '',
          itemPrice: undefined,
          itemDescription: ''
        };
      }
    },
    loading: {
      type: Boolean
    }
  },
  created() {
    this.form = cloneDeep(this.item);
  },
  computed: {
    isEditMode() {
      return !!this.form?._id;
    },
    dialogTitle() {
      if (this.isEditMode) {
        return this.$t('customer.catalogManagementPage.updateItemTitle', [
          this.form.itemCode
        ]);
      } else {
        return this.$t('customer.catalogManagementPage.createItemTitle');
      }
    },
    formChanged() {
      return !isEqual(this.item, this.form);
    }
  },
  data() {
    return {
      PriceType,
      currencyTypes,
      form: null,
      priceTypes: priceTypesItems(this),
      unitOfMeasures: unitOfMeasureItems(this)
    };
  },
  watch: {
    'form.priceType': {
      handler(value) {
        if (value === PriceType.NO_PRICE) {
          this.form.itemPrice = undefined;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-field {
  //   width: 50%;
}
</style>
