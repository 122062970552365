import PriceType from './types/PriceType';
import UnitOfMeasureType from './types/UnitOfMeasureType';

export const priceTypesItems = (vue) => {
  return [
    {
      text: vue.$t('priceTypes.noPrice'),
      value: PriceType.NO_PRICE
    },
    {
      text: vue.$t('priceTypes.withPriceChangeable'),
      value: PriceType.WITH_PRICE_CHANGEABLE
    },
    {
      text: vue.$t('priceTypes.withPriceUnchangeable'),
      value: PriceType.WITH_PRICE_UNCHANGEABLE
    }
  ];
};

export const unitOfMeasureItems = (vue) => {
  return [
    {
      text: vue.$t('unitOfMeasureTypes.piece'),
      value: UnitOfMeasureType.PIECE
    },
    {
      text: vue.$t('unitOfMeasureTypes.kg'),
      value: UnitOfMeasureType.KG
    },
    {
      text: vue.$t('unitOfMeasureTypes.hour'),
      value: UnitOfMeasureType.HOUR
    }
  ];
};
